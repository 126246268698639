<template>
  <div class="page_">
    <a-row>
      <a-col :span="20" class="img_l">
        <!-- <viewer id="index" :images="previewImages">
          <img
            class="OCRImg_"
            v-for="(img, index) in previewImages"
            :src="img.imgUrl"
            :key="index + 'img'"
          />
        </viewer> -->
        <div id="imgTooles1">
          <ul>
            <li v-for="(item, index) in previewImages" :key="index + 'img'">
              <img
                :data-original="`${item.imgUrl}`"
                :src="item.imgUrl"
                style="height: 400px; width: 500px;"
              />
            </li>
          </ul>
        </div>
        <!-- <img class="OCRImg_" src="../assets/home_bg_01.png" alt="" /> -->
        <div class="head_">
          <div>
            {{ $t('tips[15]') || '会员' }}：{{ pageInfo.baseInfo.userName }}
          </div>
          <div>
            {{ $t('claimDetail[1]') || '医院' }}：{{
              pageInfo.baseInfo.providerName
            }}
          </div>
          <div>
            {{ $t('labels[11]') || '就诊日期' }}：{{
              pageInfo.baseInfo.admissionDate
            }}
          </div>
          <div>
            {{ $t('tips[47]') || '发生金额' }}：{{ pageInfo.baseInfo.dueTotal
            }}{{ $t('Modals[18]') || '元' }}
          </div>
          <div>
            {{ $t('tips[48]') || '影像类型' }}：{{ pageInfo.baseInfo.fdesc }}
          </div>
        </div>
      </a-col>
      <a-col :span="4" class="list">
        <div class="ipt_ser">
          <a-input
            v-model="claimNo"
            class="input_"
            :placeholder="$t('tips[49]') || '请输入案件号查询影像'"
          />
          <div class="search_" @click="searchList">
            <a-icon type="search" />
          </div>
        </div>
        <div class="ipt_ser">
          <div class="txt">{{ $t('tips[50]') || '影像件列表' }}</div>
          <div class="txt theme_color">{{ $t('tips[51]') || '刷新列表' }}</div>
        </div>
        <a-list size="small" class="dataList_" bordered :dataSource="dataList">
          <a-list-item
            :style="{ cursor: 'pointer' }"
            :class="[index == activeIndex ? 'theme_color' : '']"
            @click="queryDetailList(item, index)"
            slot="renderItem"
            slot-scope="item, index"
          >
            {{ item.refno }}
          </a-list-item>
        </a-list>
      </a-col>
    </a-row>

    <div class="middle">
      <div class="mid_child mid_l">
        <div class="mid_l_txt mar_right10" :style="{ fontSize: '17px' }">
          {{ $t('tips[52]') || '费用大纲' }}
        </div>
        <div class="mid_l_txt">
          {{ $t('claimCols[0]') || '理赔编号' }}：{{
            pageInfo.baseInfo.claimNo
          }}
        </div>
      </div>
      <div class="mid_child mid_r">
        <a-checkbox class="radio_">{{
          $t('tips[53]') || '只看问题项'
        }}</a-checkbox>
        <a-button type="primary" class="mar_right10">{{
          $t('tips[54]') || '添加费用明细'
        }}</a-button>
        <a-button type="primary">{{ $t('tips[17]') || '保存' }}</a-button>
      </div>
    </div>

    <a-table
      rowKey="cfdid"
      :columns="columns"
      :dataSource="data"
      class="components-table-demo-nested myTableRow_"
      :expandedRowKeys="expendRowKeys"
    >
      <template slot="operation" slot-scope="text, record">
        <a
          href="javascript:;"
          @click="e => findDetail(e, record)"
          style="margin-right:20px;"
          >{{ $t('tips[18]') || '查看明细' }}</a
        >
        <a href="javascript:;" @click="editClaim('big', record)">{{
          $t('tips[19]') || '编辑'
        }}</a>
      </template>
      <a-table
        rowKey="cfdid"
        slot="expandedRowRender"
        slot-scope="record"
        :columns="innerColumns"
        class="myInnerTableRow_"
        :dataSource="record.childClaimFeeDetailList"
        :pagination="false"
      >
        <template slot="itemcode" slot-scope="text, record">
          <div class="isMistake_">
            <div v-if="record.code != '00'" class="mistake_">
              {{ $t('tips[20]') || '错误' }}
            </div>
            <div v-else class="mistake_1"></div>
            <div>{{ text }}</div>
          </div>
        </template>
        <template slot="operation1" slot-scope="text, record">
          <a href="javascript:;" @click="editClaim('small', record)">{{
            $t('tips[19]') || '编辑'
          }}</a>
        </template>
        <!-- <template
          v-for="col in [
            'itemcode',
            'feedetail',
            'quantity',
            'unitprice',
            'company',
            'totalprice',
            'itempaidamount',
            'discount'
          ]"
          :slot="col"
          slot-scope="text, record, index"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => handleChange(e.target.value, record.cfdid, col)"
            />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template slot="operation1" slot-scope="text, record, index">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="() => save(record.cfdid)">Save</a>
              <a-popconfirm
                title="Sure to cancel?"
                @confirm="() => cancel(record.cfdid)"
              >
                <a>Cancel</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a @click="() => edit(record.cfdid,record)">Edit</a>
            </span>
          </div>
        </template> -->
      </a-table>
    </a-table>

    <!-- 抽屉1 -->
    <a-drawer
      :title="$t('tips[53]') || '编辑费用大项'"
      wrapClassName="page_"
      :width="1000"
      @close="closeB"
      placement="left"
      :visible="visibleB"
      :wrapStyle="{
        height: 'calc(100% - 108px)',
        overflow: 'auto',
        paddingBottom: '108px'
      }"
    >
      <a-row>
        <a-col :span="20" style="height:400px;width:100%;overflow:scroll;">
          <div id="imgTooles2">
            <ul>
              <li v-for="(item, index) in previewImages" :key="index + 'img'">
                <img
                  :data-original="`${item.imgUrl}`"
                  :src="item.imgUrl"
                  style="height: 400px; width: 500px;"
                />
              </li>
            </ul>
          </div>
        </a-col>
      </a-row>
      <a-form
        :form="formB"
        layout="vertical"
        hideRequiredMark
        @submit="handleSubmit"
      >
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-item :label="$t('tips[56]') || '费用大项编码'">
              <a-select
                v-decorator="[
                  'benefitcode',
                  { initialValue: initialValueB.benefitcode }
                ]"
                :placeholder="$t('Modals[21]') || '请选择'"
              >
                <a-select-option
                  v-for="item in benefitList"
                  :key="item.code"
                  :value="item.code"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item :label="$t('tips[54]') || '费用描述'">
              <a-input
                v-decorator="[
                  'benefitdesc',
                  { initialValue: initialValueB.benefitdesc }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item :label="$t('tips[47]') || '发生金额'">
              <a-input
                v-decorator="[
                  'benefitamount',
                  { initialValue: initialValueB.benefitamount }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item :label="$t('tips[22]') || '折扣金额'">
              <a-input
                v-decorator="[
                  'benefitdiscountamount',
                  { initialValue: initialValueB.benefitdiscountamount }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('tips[23]') || '已付金额'">
              <a-input
                v-decorator="[
                  'benefitpaidamount',
                  { initialValue: initialValueB.benefitpaidamount }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div
          :style="{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }"
        >
          <a-button html-type="submit" type="primary">{{
            $t('tips[21]') || '提交'
          }}</a-button>
        </div>
      </a-form>
    </a-drawer>

    <!-- 抽屉2 -->
    <a-drawer
      :title="$t('tips[53]') || '编辑费用大项'"
      wrapClassName="page_"
      :width="1000"
      @close="closeD"
      placement="left"
      :visible="visibleD"
      :wrapStyle="{
        height: 'calc(100% - 108px)',
        overflow: 'auto',
        paddingBottom: '108px'
      }"
    >
      <a-row>
        <a-col :span="20" style="height:400px;width:100%;overflow:scroll;">
          <div id="imgTooles3">
            <ul>
              <li v-for="(item, index) in previewImages" :key="index + 'img'">
                <img
                  :data-original="`${item.imgUrl}`"
                  :src="item.imgUrl"
                  style="height: 400px; width: 500px;"
                />
              </li>
            </ul>
          </div>
        </a-col>
      </a-row>
      <a-form
        :form="formD"
        layout="vertical"
        hideRequiredMark
        @submit="handleDetailSubmit"
      >
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item :label="$t('tips[57]') || '费用明细归属编码'">
              <a-select
                v-decorator="[
                  'itemcode',
                  { initialValue: initialValueD.itemcode }
                ]"
                :placeholder="$t('Modals[21]') || '请选择'"
              >
                <a-select-option
                  v-for="item in benefitList"
                  :key="item.code"
                  :value="item.code"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="费用明细描述">
              <a-input
                v-decorator="[
                  'feedetail',
                  { initialValue: initialValueD.feedetail }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="$t('tips[25]') || '数量'">
              <a-input
                v-decorator="[
                  'quantity',
                  { initialValue: initialValueD.quantity }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="单价">
              <a-input
                v-decorator="[
                  'unitprice',
                  { initialValue: initialValueD.unitprice }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-item label="规格">
              <a-input
                v-decorator="[
                  'company',
                  { initialValue: initialValueD.company }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="总价">
              <a-input
                v-decorator="[
                  'totalprice',
                  { initialValue: initialValueD.totalprice }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="$t('claimCols[4]') || '已付金额'">
              <a-input
                v-decorator="[
                  'itempaidamount',
                  { initialValue: initialValueD.itempaidamount }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="$t('tips[22]') || '折扣金额'">
              <a-input
                v-decorator="[
                  'discount',
                  { initialValue: initialValueD.discount }
                ]"
                :placeholder="$t('tips[21]') || '请输入'"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div
          :style="{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right'
          }"
        >
          <a-button html-type="submit" type="primary">{{
            $t('tips[21]') || '提交'
          }}</a-button>
        </div>
      </a-form>
    </a-drawer>

    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
  </div>
</template>

<script>
import commonMixin from '@/mixins/common'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'

const data = []

const innerData = []
export default {
  data() {
    return {
      columns: [
        {
          title: this.$t('tips[56]') || '费用大项编码',
          dataIndex: 'benefitcode',
          key: 'benefitcode'
        },
        {
          title: this.$t('tips[54]') || '费用描述',
          dataIndex: 'benefitdesc',
          key: 'benefitdesc'
        },
        {
          title: this.$t('tips[47]') || '发生金额',
          dataIndex: 'benefitamount',
          key: 'benefitamount'
        },
        {
          title: this.$t('claimDetail[14]') || '折扣金额',
          dataIndex: 'benefitdiscountamount',
          key: 'benefitdiscountamount'
        },
        {
          title: this.$t('tips[23]') || '已付金额',
          dataIndex: 'benefitpaidamount',
          key: 'benefitpaidamount'
        },
        {
          title: this.$t('cols[7]') || '操作',
          key: 'operation',
          className: 'hotAaction',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      innerColumns: [
        {
          title: this.$t('tips[57]') || '费用明细归属编码',
          dataIndex: 'itemcode',
          key: 'itemcode',
          scopedSlots: { customRender: 'itemcode' }
        },
        {
          title: this.$t('tips[54]') || '费用明细描述',
          dataIndex: 'feedetail',
          key: 'feedetail',
          scopedSlots: { customRender: 'feedetail' }
        },
        {
          title: this.$t('tips[25]') || '数量',
          key: 'quantity',
          dataIndex: 'quantity',
          scopedSlots: { customRender: 'quantity' }
        },
        {
          title: this.$t('tips[58]') || '单价',
          dataIndex: 'unitprice',
          key: 'unitprice',
          scopedSlots: { customRender: 'unitprice' }
        },
        {
          title: this.$t('tips[59]') || '规格',
          dataIndex: 'company',
          key: 'company',
          scopedSlots: { customRender: 'company' }
        },
        {
          title: this.$t('claimDetail[16]') || '总价',
          dataIndex: 'totalprice',
          key: 'totalprice',
          scopedSlots: { customRender: 'totalprice' }
        },
        {
          title: this.$t('tips[23]') || '已付金额',
          dataIndex: 'itempaidamount',
          key: 'itempaidamount',
          scopedSlots: { customRender: 'itempaidamount' }
        },
        {
          title: this.$t('claimDetail[14]') || '折扣金额',
          dataIndex: 'discount',
          key: 'discount',
          scopedSlots: { customRender: 'discount' }
        },
        {
          title: this.$t('cols[7]') || '操作',
          key: 'operation1',
          scopedSlots: { customRender: 'operation1' }
        }
      ],
      formB: this.$form.createForm(this),
      initialValueB: {},
      visibleB: false,
      formD: this.$form.createForm(this),
      initialValueD: {},
      visibleD: false,
      claimNo: '', //案件号，传
      pushid: '', //案件号，返回
      pageInfo: {
        baseInfo: {
          userName: '李晓霞',
          providerName: '上海市第一人民医院',
          admissionDate: '2020-02-02',
          claimno: '30740170',
          dueTotal: '999,999',
          fdesc: '医疗影像'
        }
      }, //页面数据
      activeIndex: '',
      previewImages: [
        // {
        //   imgUrl: require('../assets/home_bg_01.png'),
        // },
      ],
      ViewerObj: null,
      dataList: [],
      benefitList: [
        {
          code: '123',
          name: '医药费'
        }
      ],
      expendRowKeys: [],
      data,
      innerData,
      currentItem: {}, //影像件列表当前点击的
      currentIndex: ''
    }
  },
  mixins: [commonMixin],
  mounted() {
    this.queryEspDocOcrList('ERROR')
  },
  methods: {
    closeD() {
      this.visibleD = false
      this.$nextTick(() => {
        this.initImageTools('1')
      })
    },
    closeB() {
      this.visibleB = false
      this.$nextTick(() => {
        this.initImageTools('1')
      })
    },
    initImageTools(str) {
      //初始化 viewerjs
      this.ViewerObj && this.ViewerObj.destroy()
      let ViewerDom = document.getElementById('imgTooles' + str)
      this.ViewerObj = new Viewer(ViewerDom, {
        url: 'data-original',
        inline: true,
        minWidth: 400,
        minHeight: 500,
        minZoomRatio: 0.3
      })
    },
    getbenefitList() {
      let data = {
        claimNo: this.claimNo
      }
      this.$apis.getbenefitList(data).then(res => {
        this.benefitList = res.data.data
      })
    },
    editOcrBigDetil(data) {
      this.$apis.editOcrBigDetil(data).then(() => {
        this.freshData()
        this.$message.success(this.$t('tips[26]') || '编辑成功')
      })
    },
    editChildClaimFee(data) {
      this.$apis.editChildClaimFee(data).then(() => {
        this.freshData()
        this.$message.success(this.$t('tips[26]') || '编辑成功')
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.formB.validateFields((err, values) => {
        if (!err) {
          let params = {
            cfdid: this.initialValueB.cfdid,
            ...values
          }
          this.editOcrBigDetil(params)
          this.visibleB = false
          this.$nextTick(() => {
            this.initImageTools('1')
          })
        }
      })
    },
    handleDetailSubmit(e) {
      e.preventDefault()
      this.formD.validateFields((err, values) => {
        if (!err) {
          let params = {
            cfdid: this.initialValueD.cfdid,
            ...values
          }
          this.editChildClaimFee(params)
          this.visibleD = false
          this.$nextTick(() => {
            this.initImageTools('1')
          })
        }
      })
    },
    editClaim(type, record) {
      this.getbenefitList()
      if (type == 'big') {
        this.initialValueB = record
        this.visibleB = true
        this.$nextTick(() => {
          this.initImageTools('2')
        })
      } else {
        this.initialValueD = record
        this.visibleD = true
        this.$nextTick(() => {
          this.initImageTools('3')
        })
      }
    },
    //根据案件号查询影像件列表（精确查找）
    queryEspDocOcrList(data) {
      this.$apis.queryEspDocOcrList(data).then(res => {
        this.dataList = res.data.content.espDocOcrList
        this.claimNo = this.dataList[0].pushid
        this.queryDetailList(this.dataList[0], 0)
        // this.pushid = res.data.content
      })
    },
    searchList() {
      if (this.claimNo == '') {
        this.$message.warning(this.$t('tips[49]') || '请输入案件号查询影像')
      } else {
        this.queryEspDocOcrList(this.claimNo)
      }
    },
    freshData() {
      this.$apis
        .queryClaimFeeDetailList(
          this.currentItem.pushid,
          this.currentItem.refno
        )
        .then(res => {
          let content = res.data.content
          this.pageInfo = content
          this.data = content.claimFeeDetailList
          if (content.imgList) {
            this.previewImages = content.imgList
          }
        })
    },
    queryDetailList(item, index) {
      ;(this.currentItem = item), this.showSpin()
      this.activeIndex = index
      this.$apis
        .queryClaimFeeDetailList(item.pushid, item.refno)
        .then(res => {
          let content = res.data.content
          this.pageInfo = content
          this.data = content.claimFeeDetailList
          this.claimNo = content.baseInfo.claimNo
          if (content.imgList) {
            this.previewImages = content.imgList
            this.$nextTick(() => {
              this.initImageTools('1')
            })
          }
        })
        .finally(() => {
          this.hideSpin()
        })
    },
    findDetail(e, record) {
      let delIndex
      let isExit = this.expendRowKeys.find((item, index) => {
        delIndex = index
        return item == record.cfdid
      })

      if (isExit != undefined) {
        this.expendRowKeys.splice(delIndex, 1)
      } else {
        this.expendRowKeys.push(record.cfdid)
      }
      record.isShow = !record.isShow
    }
  }
}
</script>

<style scoped lang="scss">
.page_ {
  .viewer-canvas {
    background: #001529;
  }
  padding: 20px;
  .img_l {
    height: 500px;
    position: relative;
    overflow: hidden;
    .OCRImg_ {
      width: 100%;
    }
    .head_ {
      position: absolute;
      top: 0;
      left: 0;
      height: 45px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 9px;
      font-weight: 400;
      color: rgba(240, 241, 245, 1);
      align-items: center;
      background: rgba(0, 0, 0, 1);
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
      opacity: 0.5;
    }
  }
  .list {
    padding: 0 10px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    .ipt_ser {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input_ {
        width: 135px;
      }
      .search_ {
        cursor: pointer;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: #00c55d;
        color: #fff;
        border-radius: 3px;
      }
    }
    .dataList_ {
      height: 430px;
      overflow-y: scroll;
      border-left: 0;
      border-right: 0;
      border: 0;
      border-radius: 0;
    }
  }
  .middle {
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    .mid_child {
      flex: 1;
      display: flex;
      font-size: 11px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      .mid_l_txt {
        display: flex;
        align-items: flex-end;
      }
      .radio_ {
        display: flex;
        align-items: center;
      }
    }
    .mid_r {
      justify-content: flex-end;
    }
  }

  .isMistake_ {
    display: flex;
    // justify-content: center;
    .mistake_ {
      padding: 2px;
      opacity: 0.5;
      border-radius: 2px;
      font-size: 2px;
      transform: scale(0.7, 0.7);
      text-align: center;
      color: #fff;
      background: rgba(224, 0, 0, 1);
      margin-right: 2px;
    }
    .mistake_1 {
      width: 30px;
    }
  }

  .dataList_::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .dataList_::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  .dataList_::-webkit-scrollbar-thumb {
    background: #00bd59;
    border-radius: 10px;
  }

  .txt {
    font-size: 9px;
    font-weight: 600;
    color: rgba(153, 153, 153, 1);
  }
  .theme_color {
    color: #00c55d;
  }
  .mar_right10 {
    margin-right: 10px !important;
  }
}
</style>
