<template>
  <div class="page_ page_welfare_">
    <a-form :form="searchForm" layout="horizontal" @submit="handleSearchForm">
      <a-form-item
        class="c_form_item_ no_mrgtop_"
        :label="$t('labels[0]') || '查询项'"
      >
        <a-radio-group @change="onAChange" v-model="aValue">
          <a-radio :value="1">{{ $t('cols[4]') || '保险卡号' }}</a-radio>
          <a-radio :value="2">{{ $t('cols[3]') || '证件号' }}</a-radio>
          <a-radio :value="3">{{ $t('cols[0]') || '姓名' }}</a-radio>
          <a-radio :value="4">{{ $t('labels[4]') || '保单号' }}</a-radio>
          <a-radio :value="5">{{ $t('labels[5]') || '病历号' }}</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-row class="c_form_item_ h58_" :gutter="12">
        <a-col :span="6">
          <a-form-item :label="$t('labels[0]') || '查询内容'">
            <a-input
              @change="handleContentChange"
              v-decorator="[
                'content',
                {
                  initialValue: searchContent
                }
              ]"
              :placeholder="cPlaceholder"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col v-if="aValue == 2" :span="6" class="marLeft_20">
          <a-form-item :label="$t('claimDetail[20]') || '证件类型'">
            <a-select
              v-decorator="[
                'idType',
                {
                  initialValue: searchContent
                }
              ]"
              :placeholder="$t('Modals[21]') || '请选择'"
            >
              <a-select-option key="1" value="00">{{
                $t('labels[34]') || '身份证'
              }}</a-select-option>
              <a-select-option key="2" value="01">{{
                $t('labels[35]') || '护照'
              }}</a-select-option>
              <a-select-option key="3" value="02">{{
                $t('labels[36]') || '其他'
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col v-if="aValue == 3" :span="6" class="marLeft_20">
          <a-form-item :label="$t('cols[1]') || '生日'">
            <a-date-picker
              v-decorator="['birthDate', { initialValue: undefined }]"
              :placeholder="$t('Modals[21]') || '请选择'"
              valueFormat="YYYY-MM-DD"
              :format="dateFormat"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="2" :offset="1" class="marLeft_20">
          <a-button html-type="submit" type="primary">{{
            $t('btns[0]') || '查询'
          }}</a-button>
        </a-col>
        <a-col :span="2">
          <a-button type="primary" ghost @click="resetConditions">{{
            $t('btns[18]') || '重置'
          }}</a-button>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      style="margin-top: 20px;"
      :rowKey="(record, index) => record.empPlanId + '_' + index"
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @change="handleTableChange"
      v-if="isShowTable"
    >
      <template slot="do" slot-scope="text, record">
        <a @click="showDetail(record)">{{ $t('btns[2]') || '查看福利' }}</a>
      </template>
    </a-table>
    <WelfareDetail :data="detailData" v-else />
  </div>
</template>
<style lang="scss" scoped>
.contact_kefu_ {
  position: absolute;
  top: 10px;
  right: 15px;
}

.more_list_wrap_ {
  li {
    border-top: 1px solid #d9d9d9;
    text-align: center;
    line-height: 32px;
    min-width: 160px;
    cursor: pointer;
    &:hover {
      color: #027db4;
    }
  }
}

.marLeft_20 {
  margin-left: 20px;
}
</style>
<script>
import Bus from '@/utils/Bus'
import moment from 'moment'
import commonMixin from '@/mixins/common'
import authorizedMixin from '@/mixins/authorized'

export default {
  data() {
    const columns = [
      {
        title: this.$t('cols[0]') || '姓名',
        dataIndex: 'employeeName',
        key: 'employeeName'
      },
      {
        title: this.$t('cols[1]') || '生日',
        dataIndex: 'birthDate',
        key: 'birthDate',
        scopedSlots: { customRender: 'birthDate' }
      },
      {
        title: this.$t('cols[2]') || '性别',
        dataIndex: 'gender',
        key: 'gender',
        scopedSlots: { customRender: 'gender' }
      },
      {
        title: this.$t('cols[3]') || '证件号',
        dataIndex: 'idNumber',
        key: 'idNumber'
      },
      {
        title: this.$t('cols[4]') || '保险卡号',
        dataIndex: 'cardNo',
        key: 'cardNo'
      },
      {
        title: this.$t('labels[4]') || '保单号',
        dataIndex: 'policyNo',
        key: 'policyNo'
      },
      {
        title: this.$t('cols[6]') || '保险公司',
        dataIndex: 'companyName',
        key: 'companyName'
      },
      {
        title: this.$t('cols[7]') || '操作',
        dataIndex: 'do',
        key: 'do',
        scopedSlots: { customRender: 'do' }
      }
    ]

    return {
      groupCode: '',
      planType: '',
      preauth: '', //预授权
      isShowTable: true,
      columns,
      content: '',
      postKey: 'cardNo',
      empPlanId: 1001,
      data: [],
      values: {},
      pagination: {
        current: 1,
        total: 0,
        pageNum: 1,
        pageSize: 10
      },
      id: 1,
      aValue: 1,
      detailData: {},
      searchForm: this.$form.createForm(this),
      cPlaceholder: this.$t('tips[10]') || '请输入保险卡号',
      dateFormat: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD']
    }
  },
  mixins: [commonMixin, authorizedMixin],
  mounted() {
    if (this.$route.query.cardNo) {
      this.searchForm.setFieldsValue({
        content: this.$route.query.cardNo
      })
      this.handleSearchForm()
    }
  },
  methods: {
    moment,
    handleContact() {
      const code = 'k54bbij'
      const link = `https://mdl.s2.udesk.cn/im_client/?web_plugin_id=22884&code=${code}`
      window.ud({
        code,
        link
      })
      window.open(link)
    },
    showDetail(record) {
      this.isShowTable = false
      this.detailData = record
      Bus.$emit('empPlanId', {
        data: this.detailData
      })
    },
    queryTableList(data) {
      this.$apis
        .queryTableList(data)
        .then(res => {
          if (res.data.data.benefitShowVoList.length < 2) {
            this.detailData = res.data.data.benefitShowVoList[0] || {}
            Bus.$emit('empPlanId', {
              data: this.detailData
            })
            this.isShowTable = false
          } else {
            this.isShowTable = true
            this.data = res.data.data.benefitShowVoList
            this.pagination.total = res.data.data.totalSize
          }
          if (res.data.code != 200) {
            this.data = []
          }
        })
        .catch(() => {
          this.data = []
          this.pagination.total = 0
        })
    },
    handleSearchForm(e) {
      e && e.preventDefault()
      this.searchForm.validateFields((err, values) => {
        if (!err) {
          this.values = values
          this.pagination.current = 1
          let searchContent = values.content

          if (this.postKey == 'cardNo') {
            let content = values.content.split('-').join('')
            searchContent = content == '8000-1428' ? '' : content
          }

          if (
            (searchContent == '80001428' || searchContent == '') &&
            this.aValue == 1
          ) {
            this.$message.warning(this.$t('tips[29]') || '请输入查询内容')
          } else if (
            searchContent == '' &&
            this.aValue != 3 &&
            this.aValue != 2
          ) {
            this.$message.warning(this.$t('tips[29]') || '请输入查询内容')
          } else if (
            searchContent == '' &&
            this.aValue == 3 &&
            !values.birthDate
          ) {
            this.$message.warning(this.$t('tips[29]') || '请输入查询内容')
          } else if (
            searchContent == '' &&
            this.aValue == 2 &&
            !values.idType
          ) {
            this.$message.warning(this.$t('tips[29]') || '请输入查询内容')
          } else {
            let data = {
              [this.postKey]: searchContent,
              pageNo: 1,
              pageSize: this.pagination.pageSize
            }
            if (this.aValue == 3 && values.birthDate) {
              data.birthDate = values.birthDate
            } else if (this.aValue == 2 && values.idType) {
              data.idType = values.idType
            }
            this.queryTableList(data)
          }
        }
      })
    },
    onAChange(e) {
      const val = e.target.value
      let str = ''
      this.searchContent = ''
      const delay = func => {
        let st = setTimeout(() => {
          clearTimeout(st)
          func && func()
        }, 300)
      }
      delay(() => {
        this.searchForm.setFieldsValue({
          content: ''
        })
      })
      switch (val) {
        case 1:
          str = this.$t('tips[21]') || '请输入'
          this.postKey = 'cardNo'
          this.searchContent = '8000-1428'
          delay(() => {
            this.searchForm.setFieldsValue({
              content: '8000-1428'
            })
          })
          break
        case 2:
          str = this.$t('tips[11]') || '请输入客户证件号'
          this.postKey = 'idNumber'
          break
        case 3:
          str = this.$t('tips[12]') || '请输入客户姓名'
          this.postKey = 'employeeName'
          break
        case 4:
          str = this.$t('tips[13]') || '请输入客户保单号'
          this.postKey = 'policyNo'
          break
        case 5:
          str = this.$t('tips[14]') || '请输入客户病历号'
          this.postKey = 'disabilityNo'
          break
      }
      this.cPlaceholder = str
    },
    handleTableChange(pagination) {
      pagination.pageNum = pagination.current
      this.pagination = { ...pagination }
      let data = {
        [this.postKey]: this.values.content,
        pageNo: pagination.current,
        pageSize: this.pagination.pageSize
      }
      this.queryTableList(data)
    },
    resetConditions() {
      this.searchContent = ''
      switch (this.aValue) {
        case 1:
          this.searchContent = '8000-1428'
          this.searchForm.setFieldsValue({
            content: '8000-1428'
          })
          break
        case 2:
          this.searchForm.setFieldsValue({
            idType: '',
            content: ''
          })
          break
        case 3:
          this.searchForm.setFieldsValue({
            birthDate: null,
            content: ''
          })
          break
      }
    }
  }
}
</script>
