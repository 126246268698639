<template>
  <div class="page_">
    <a-form-model :model="searchData">
      <a-row :gutter="16">
        <a-col :sm="12" :md="6">
          <a-form-model-item label="状态">
            <a-select
              allowClear
              v-model="searchData.packageStatus"
              placeholder="状态"
              style="border: 1px solid #d9d9d9;"
            >
              <a-select-option
                v-for="item in statusOptions"
                :value="item.value"
                :key="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :sm="12" :md="12">
          <a-form-model-item label="上传日期">
            <a-range-picker v-model="searchData.rangeDate" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :sm="12" :md="6">
          <a-form-model-item>
            <a-button type="primary" @click.stop="pageChange(1)">查询</a-button>
            <a-button type="primary" class="m-l10" @click.stop="exportEvt"
              >导出</a-button
            >
            <a-button @click="openUploadDialog" class="m-l10" type="primary">{{
              $t('dashboard.upload') || '理赔件批量上传'
            }}</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table :columns="columns" :dataSource="dataList" :pagination="false">
      <template slot="packageName" slot-scope="text, record">
        <a :href="record.packageUrl" target="_blank">{{ text }}</a>
      </template>
      <template slot="createTime" slot-scope="text">
        {{ moment(text).format('YYYY-MM-DD') }}
      </template>
      <template slot="failFileList" slot-scope="text">
        <div>
          <div class="list" v-for="(item, i) in text" :key="i">
            {{ item.fileName }}
            <a-button type="primary" @click.stop="editEvt(item)" size="small"
              >编辑</a-button
            >
          </div>
        </div>
      </template>
    </a-table>
    <div class="flex my-pagination">
      <a-pagination
        v-model="pagination.pageNum"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        show-size-changer
        @change="pageChange"
        @showSizeChange="onShowSizeChange"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
          <span v-if="props.value === '50'">全部</span>
        </template>
      </a-pagination>
    </div>
    <a-modal
      title="编辑"
      :visible="dialogShow"
      width="700px"
      :maskClosable="false"
      :ok-text="$t('common.confirm')"
      :cancel-text="$t('common.cancel')"
      @ok="handleEditOK"
      @cancel="dialogShow = false"
    >
      <a-form-model ref="editForm" :model="editFormObj" :rules="editRules">
        <a-row :gutter="30">
          <a-col :lg="12" :xs="24">
            <a-form-model-item label="文件名称" prop="trackingNo">
              <a-input
                v-model.trim="editFormObj.fileName"
                placeholder="文件名称"
                disabled
              />
            </a-form-model-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <a-form-model-item label="CLAIM ID" prop="claimId">
              <a-input
                v-model.trim="editFormObj.claimId"
                placeholder="CLAIM ID"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <ClaimFileUpload ref="claimFileUpload" @onSuccess="getData" />
  </div>
</template>

<script>
import moment from 'moment'
import common from '@/mixins/common'
import ClaimFileUpload from './components/ClaimFileUpload.vue'
export default {
  data() {
    return {
      searchData: {
        rangeDate: []
      },
      statusOptions: [
        { name: '待处理', value: 1 },
        { name: '处理完成', value: 2 },
        { name: '处理中', value: 5 },
        { name: '处理失败', value: 9 }
      ],
      columns: [
        {
          title: '文件名称',
          dataIndex: 'packageName',
          key: 'packageName',
          scopedSlots: { customRender: 'packageName' }
        },
        {
          title: '上传日期',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        { title: '上传用户', dataIndex: 'createBy', key: 'createBy' },
        { title: '子文件数量', dataIndex: 'num', key: 'num' },
        { title: '备注', dataIndex: 'remark', key: 'remark' },
        {
          title: '状态',
          dataIndex: 'processStatusDesc',
          key: 'processStatusDesc'
        },
        {
          title: '失败文件',
          dataIndex: 'failFileList',
          key: 'failFileList',
          scopedSlots: { customRender: 'failFileList' },
          width: 300
        }
      ],
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dataList: [],
      dialogShow: false,
      editFormObj: {
        leafId: ''
      },
      editRules: {
        claimId: [
          { required: true, message: '请输入CLAIM ID', trigger: 'blur' }
        ]
      }
    }
  },
  components: { ClaimFileUpload },
  mixins: [common],
  created() {
    this.getData()
  },
  methods: {
    moment,
    // 理赔件批量上传
    openUploadDialog() {
      this.$refs.claimFileUpload.openUploadDialog()
    },
    // 改变页面数据
    onShowSizeChange(current, pageSize) {
      this.pagination.pageSize = pageSize
      this.getData()
    },
    // 分页
    pageChange(e) {
      this.pagination.pageNum = e
      this.getData()
    },
    // 获取列表数据
    getData() {
      this.$apis
        .queryOrcBatchUploadList({
          pageNum: this.pagination.pageNum,
          pageSize: this.pagination.pageSize,
          ...this.searchData,
          uploadDateStart: this.searchData.rangeDate[0]
            ? moment(this.searchData.rangeDate[0]).format('YYYY-MM-DD')
            : '',
          uploadDateEnd: this.searchData.rangeDate[1]
            ? moment(this.searchData.rangeDate[1]).format('YYYY-MM-DD')
            : ''
        })
        .then(res => res.data)
        .then(res => {
          if (res.returnCode == '0000') {
            this.dataList = res.content.list || []
            this.pagination.total = res.content.total
          } else {
            this.$message.error(res.returnMsg)
          }
        })
    },
    // 导出
    exportEvt() {
      this.$apis
        .exportOrcUploadList({
          pageNum: this.pagination.pageNum,
          pageSize: this.pagination.pageSize,
          ...this.searchData,
          uploadDateStart: this.searchData.rangeDate[0]
            ? moment(this.searchData.rangeDate[0]).format('YYYY-MM-DD')
            : '',
          uploadDateEnd: this.searchData.rangeDate[1]
            ? moment(this.searchData.rangeDate[1]).format('YYYY-MM-DD')
            : ''
        })
        .then(res => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          })
          const a = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          const filename = '影像件批量上传列表.xls'
          a.href = url
          a.download = filename
          a.click()
          window.URL.revokeObjectURL(url)
        })
    },
    editEvt(obj = {}) {
      this.editFormObj = {
        ...obj,
        leafId: obj.id
      }
      this.dialogShow = true
    },
    handleEditOK() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$apis
            .setFileClaimId({
              ...this.editFormObj
            })
            .then(res => res.data)
            .then(res => {
              if (res.returnCode == '0000') {
                this.$message.success('编辑成功')
                this.dialogShow = false
                this.getData()
              } else {
                this.$message.error(res.returnMsg)
              }
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
#admin-layout-container .ant-btn.m-l10 {
  margin-left: 10px;
}
/deep/ .ant-table {
  margin-top: 10px;
}
.my-pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
