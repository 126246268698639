<template>
  <div class="page_">
    <a-radio-group
      @change="handleStatusChange"
      v-model="conFlag"
      class="c_form_item_ no_top_mrg_"
      size="large"
    >
      <a-radio-button value="">{{
        $t('labels[12]') || '全部授权'
      }}</a-radio-button>
      <a-radio-button value="11"
        >{{ $t('labels[13]') || '待批准'
        }}<span class="tab_num_">{{ waitSize }}</span></a-radio-button
      >
      <a-radio-button value="12">{{
        $t('labels[14]') || '已批准'
      }}</a-radio-button>
      <a-radio-button value="13">{{
        $t('labels[15]') || '拒绝批准'
      }}</a-radio-button>
      <a-radio-button value="10"
        >{{ $t('labels[8]') || '补件'
        }}<span class="tab_num_">{{ patchPageSize }}</span></a-radio-button
      >
    </a-radio-group>
    <a-form :form="searchForm" layout="horizontal" @submit="handleSearchForm">
      <a-form-item
        class="c_form_item_ pad15_ h58_"
        :label="$t('labels[0]') || '查询项'"
      >
        <a-row style="margin-left: 12px;">
          <a-col :span="16">
            <a-radio-group @change="onAChange" v-model="aValue">
              <a-radio :value="1">{{ $t('cols[4]') || '保险卡号' }}</a-radio>
              <a-radio :value="2">{{ $t('cols[3]') || '证件号' }}</a-radio>
              <a-radio :value="3">{{ $t('cols[0]') || '姓名' }}</a-radio>
              <a-radio :value="4">{{ $t('labels[4]') || '保单号' }}</a-radio>
              <a-radio :value="5">{{ $t('labels[5]') || '病历号' }}</a-radio>
            </a-radio-group>
          </a-col>
          <a-col class="p_static_" :span="8">
            <div class="right_">
              <a-checkbox @change="onSelectAll">{{
                $t('btns[3]') || '勾选全部记录'
              }}</a-checkbox>
              <a-button @click="exportAuthData" type="primary">{{
                $t('btns[4]') || '导出数据'
              }}</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-item>
      <a-row class="c_form_item_ h58_" :gutter="12">
        <a-col v-if="aValue == 2" :span="6" class="marLeft_20">
          <a-form-item :label="$t('claimDetail[20]') || '证件类型'">
            <a-select
              v-decorator="['idType']"
              :placeholder="$t('Modals[21]') || '请选择'"
            >
              <a-select-option key="1" value="00">{{
                $t('labels[34]') || '身份证'
              }}</a-select-option>
              <a-select-option key="2" value="01">{{
                $t('labels[35]') || '护照'
              }}</a-select-option>
              <a-select-option key="3" value="02">{{
                $t('labels[36]') || '其他'
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" class="marLeft_20">
          <a-form-item :label="$t('labels[0]') || '查询内容'">
            <a-input
              @change="handleContentChange"
              v-decorator="[
                'content',
                {
                  initialValue: searchContent
                }
              ]"
              :placeholder="cPlaceholder"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col v-if="aValue == 3" :span="6" class="marLeft_20">
          <a-form-item :label="$t('cols[1]') || '生日'">
            <a-date-picker
              v-decorator="['birthDate', { initialValue: undefined }]"
              :placeholder="$t('Modals[21]') || '请选择'"
              valueFormat="YYYY-MM-DD"
              :format="dateFormat"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item :label="$t('labels[11]') || '就诊日期'">
            <a-range-picker
              v-decorator="['rangeDate', { initialValue: undefined }]"
              :placeholder="[
                $t('tips[27]') || '开始时间',
                $t('tips[28]') || '结束时间'
              ]"
              valueFormat="YYYY-MM-DD"
              :format="dateFormat"
            ></a-range-picker>
          </a-form-item>
        </a-col>
        <a-col :span="2" class="marLeft_20">
          <a-button html-type="submit" type="primary">{{
            $t('btns[0]') || '查询'
          }}</a-button>
        </a-col>
        <a-col :span="2">
          <a-button type="primary" ghost @click="resetConditions">{{
            $t('btns[18]') || '重置'
          }}</a-button>
        </a-col>
      </a-row>
    </a-form>
    <div style="width: 100%;overflow-x: auto;">
      <a-table
        :rowKey="record => record.claimsId"
        style="margin-top: 20px;"
        :pagination="pagination"
        @change="handlePageChange"
        :columns="columns"
        :dataSource="data"
      >
        <template slot="check" slot-scope="text, record, index">
          <a-checkbox
            :checked="checkedVals.indexOf(record.claimsId) > -1"
            @change="e => handleRecordSelection(e, record, index)"
          ></a-checkbox>
        </template>
        <template slot="authorizationCode" slot-scope="text, record">
          <router-link :to="`authDetail/${record.claimsId}`">{{
            text
          }}</router-link>
        </template>
        <template slot="estBills" slot-scope="text">
          {{ text | addCommas }}
        </template>
        <template slot="do" slot-scope="text, record">
          <auth-buttons
            @onSuccess="fetchData"
            @cancel="cancelPreAuth"
            type="1"
            :record="record"
          ></auth-buttons>
        </template>
      </a-table>
    </div>
    <claim-about-modals></claim-about-modals>
    <!-- 预授权申请 -->
    <apply-pre-auth
      :preAuthShow="preAuthShow"
      :record="applyPreAuthRecord"
      @preAuthClose="preAuthClose"
      @fetchData="fetchData"
    />
    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
  </div>
</template>
<script>
import commonMixin from '@/mixins/common'
import authorizedMixin from '@/mixins/authorized'
import ClaimAboutModals from './components/ClaimAboutModals'
import AuthButtons from './components/AuthButtons'
import ApplyPreAuth from '../views/components/ApplyPreAuth'
import Bus from '@/utils/Bus'

export default {
  data() {
    const columns = [
      {
        title: '',
        key: 'idNum',
        scopedSlots: { customRender: 'check' },
        width: '50px'
      },
      {
        title: this.$t('common.index'),
        width: '50px',
        customRender: (t, r, index) => {
          return parseInt(
            `${(this.pagination.current - 1) * this.pagination.pageSize +
              (index + 1)}`
          )
        }
      },
      {
        title: this.$t('preAuthCols[1]') || '授权编号',
        dataIndex: 'authorizationCode',
        key: 'authorizationCode',
        scopedSlots: { customRender: 'authorizationCode' },
        sorter: true
      },
      {
        title: this.$t('preAuthCols[11]') || '预授权编号',
        dataIndex: 'claimsId',
        key: 'claimsId',
        sorter: true
      },
      {
        title: this.$t('cols[0]') || '姓名',
        dataIndex: 'patientName',
        key: 'patientName',
        sorter: true
      },
      {
        title: this.$t('cols[4]') || '保险卡号',
        dataIndex: 'cardNo',
        key: 'cardNo',
        sorter: true
      },
      {
        title: this.$t('cols[3]') || '证件号',
        dataIndex: 'newIc',
        key: 'newIc',
        sorter: true
      },
      {
        title: this.$t('cols[1]') || '生日',
        dataIndex: 'birthDate',
        key: 'birthDate',
        sorter: true
      },
      {
        title: this.$t('preAuthCols[1]') || '预计就诊日期',
        dataIndex: 'admissionDate',
        key: 'admissionDate',
        sorter: true
      },
      {
        title: this.$t('preAuthCols[2]') || '预估账单金额',
        dataIndex: 'estBills',
        key: 'estBills',
        scopedSlots: { customRender: 'estBills' },
        sorter: true
      },
      {
        title: this.$t('preAuthCols[3]') || '预估天数',
        dataIndex: 'estLos',
        key: 'estLos',
        sorter: true
      },
      {
        title: this.$t('claimCols[7]') || '保险人',
        dataIndex: 'employeeName',
        key: 'employeeName',
        sorter: true
      },
      {
        title: this.$t('claimCols[8]') || '保险责任',
        dataIndex: 'coverageId',
        key: 'coverageId',
        sorter: true
      },
      {
        title: this.$t('preAuthCols[4]') || '授权状态',
        dataIndex: 'claimsStatusName',
        key: 'claimsStatusName',
        sorter: true
      },
      {
        width: 120,
        title: this.$t('cols[7]') || '操作',
        key: 'do',
        scopedSlots: { customRender: 'do' }
      }
    ]
    return {
      checkedVals: [],
      columns,
      data: [],
      waitSize: undefined,
      refuseSize: undefined,
      approvalSize: undefined,
      patchPageSize: undefined,
      id: 1,
      aValue: 1,
      searchForm: this.$form.createForm(this),
      cPlaceholder: this.$t('tips[10]') || '请输入保险卡号',
      pagination: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '150', '200'],
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      conFlag: '',
      dateFormat: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD'],
      pageOrderBy: '',
      applyPreAuthRecord: {}, // 申请预授权数据
      preAuthShow: false // 预授权申请弹窗标识
    }
  },
  components: {
    'claim-about-modals': ClaimAboutModals,
    'auth-buttons': AuthButtons,
    'apply-pre-auth': ApplyPreAuth
  },
  mixins: [commonMixin, authorizedMixin],
  created() {
    Bus.$off('handlePreAuth')
    Bus.$on('handlePreAuth', options => {
      this.handlePreAuth(options.data)
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 取消预授权
    // todo 加接口调用
    cancelPreAuth(record) {
      console.log(record)
      this.$confirm({
        title: this.$t('common.confirmCancelTip') || '确认删除此条数据?',
        okText: this.$t('common.confirm') || '确定',
        cancelText: this.$t('common.cancel') || '取消',
        onOk: () => {
          this.$apis
            .cancelPreAuth({
              claimsId: record.claimsId,
              cardNo: record.cardNo
            })
            .then(res => {
              if (res && res.data.code == 200) {
                this.$message.success('操作成功！')
                this.fetchData()
                return
              }
              this.$message.error(res.data.msg)
            })
        }
      })
    },
    handleStatusChange() {
      this.pagination.current = 1
      this.fetchData()
    },
    handlePageChange(page, filter, sorter) {
      if (!this.data || !this.data.length) {
        return false
      }
      this.pagination.current = page.current
      let { field, order } = sorter
      order = order ? (order === 'ascend' ? 'asc' : 'desc') : undefined
      this.pageOrderBy = order ? `"${field}" ${order}` : ''
      this.fetchData()
    },
    handleRecordSelection(e, record, index) {
      if (this.checkedVals.indexOf(record.claimsId) == -1) {
        this.checkedVals.push(record.claimsId)
      } else {
        this.checkedVals.splice(index, 1)
      }
    },
    handleFileUpload(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    fetchData(no, returnOptions) {
      let params = {
        pageIndex: this.pagination.current,
        pageSize: this.pagination.pageSize,
        claimsStatus: this.conFlag,
        pageOrderBy: this.pageOrderBy
      }
      let content = undefined
      this.searchForm.validateFields((err, options) => {
        if (!err) {
          if (options) {
            switch (this.aValue) {
              case 1:
                content = options.content.split('-').join('')
                params.cardNo = content == '80001428' ? '' : content
                break
              case 2:
                params.newIc = options.content
                break
              case 3:
                params.patientName = options.content
                break
              case 4:
                params.policyNo = options.content
                break
              case 5:
                break
            }
            if (options.rangeDate && options.rangeDate.length > 1) {
              params.admissionDateBegin = options.rangeDate[0].format(
                'YYYY-MM-DD'
              )
              params.admissionDateEnd = options.rangeDate[1].format(
                'YYYY-MM-DD'
              )
            }
            if (options.idType) {
              params.idType = options.idType
            }
            if (options.birthDate) {
              params.birthDate = options.birthDate
            }
          }
        }
      })
      if (returnOptions) {
        return params
      }
      this.$apis
        .authList(params)
        .then(res => res.data)
        .then(data => {
          const {
            totalPageSize,
            patchPageSize,
            approvalSize,
            refuseSize,
            waitSize,
            list
          } = data.data
          this.data = list
          this.pagination.total = totalPageSize
          this.patchPageSize = patchPageSize
          this.approvalSize = approvalSize
          this.refuseSize = refuseSize
          this.waitSize = waitSize
        })
    },
    handleSearchForm(e) {
      e && e.preventDefault()
      this.pagination.current = 1
      this.fetchData()
    },
    onSelectAll(e) {
      if (e.target.checked) {
        this.checkedVals = this.data.map(item => item.claimsId)
      } else {
        this.checkedVals = []
      }
    },
    onAChange(e) {
      const val = e.target.value
      let str = ''
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: ''
      })
      switch (val) {
        case 1:
          str = this.$t('tips[21]') || '请输入'
          this.searchContent = '8000-1428'
          this.searchForm.setFieldsValue({
            content: '8000-1428'
          })
          break
        case 2:
          str = this.$t('tips[11]') || '请输入客户证件号'
          break
        case 3:
          str = this.$t('tips[12]') || '请输入客户姓名'
          break
        case 4:
          str = this.$t('tips[13]') || '请输入客户保单号'
          break
        case 5:
          str = this.$t('tips[14]') || '请输入客户病历号'
          break
      }
      this.cPlaceholder = str
    },
    exportAuthData() {
      const params = this.fetchData(1, 1)
      this.$apis.exportAuthData(params).then(res => {
        const blob = new Blob([res.data], { type: 'application/zip' })
        const filename = res.headers['content-disposition']
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        ;[downloadElement.download] = [filename.split('=')[1]]
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放blob对象
      })
    },
    resetConditions() {
      this.searchContent = ''
      this.searchForm.setFieldsValue({
        content: '',
        rangeDate: []
      })
      switch (this.aValue) {
        case 1:
          this.searchContent = '8000-1428'
          this.searchForm.setFieldsValue({
            content: '8000-1428'
          })
          break
        case 2:
          this.searchForm.setFieldsValue({
            idType: ''
          })
          break
        case 3:
          this.searchForm.setFieldsValue({
            birthDate: null
          })
          break
      }
    },
    // 预授权申请
    handlePreAuth(record) {
      this.preAuthShow = true
      this.applyPreAuthRecord = record
    },
    // 关闭预授权申请弹窗
    preAuthClose() {
      this.preAuthShow = false
    }
  }
}
</script>
