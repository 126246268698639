<template>
  <!-- 批量上传弹框 -->
  <div class="container" v-if="showUploadDialog">
    <div class="dialog_box">
      <h4>{{ $t('dashboard.upload') || '理赔件批量上传' }}</h4>
      <div>
        <a-upload
          :remove="handleRemove"
          :beforeUpload="beforeUpload"
          :file-list="fileList"
          class="upload-list-inline"
        >
          <span class="lable"
            >{{ $t('dashboard.claimDocuments') || '理赔材料' }}:</span
          >
          <a-button>
            {{ $t('dashboard.selectFile') || '选择文件' }}
          </a-button>
        </a-upload>
      </div>
      <div class="remark_box">
        <p class="lable">{{ $t('dashboard.remark') || '备注' }}:</p>
        <a-textarea
          v-model="remark"
          placeholder="请输入备注"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </div>
      <div class="btn_group">
        <a-button
          @click="handleUpload"
          type="primary"
          :loading="uploading"
          class="mar_right25"
          >{{ $t('dashboard.confirm') || '确认' }}</a-button
        >
        <a-button @click="onCancel">{{
          $t('dashboard.cancel') || '取消'
        }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showUploadDialog: false,
      remark: '', // 备注
      fileList: [],
      uploading: false
    }
  },
  methods: {
    // 理赔件批量上传
    openUploadDialog() {
      this.fileList = []
      this.remark = ''
      this.showUploadDialog = true
    },
    onCancel() {
      this.showUploadDialog = false
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      this.fileList = [file]
      return false
    },
    handleUpload() {
      const { fileList } = this
      const formData = new FormData()
      if (!fileList.length) {
        this.$message.error(this.$t('dashboard.zipNotEmpty'))
        return
      }
      fileList.forEach(file => {
        formData.append('file', file)
      })
      formData.append('remark', this.remark)

      this.uploading = true
      this.$apis.batchUploadImages(formData).then(res => {
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg || '资料上传失败')
        }
        this.uploading = false
        this.$message.success(this.$t('dashboard.tips'))
        this.fileList = []
        this.remark = ''
        this.showUploadDialog = false
        this.$emit('onSuccess')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .mar_right25 {
    margin-right: 25px !important;
  }
  .dialog_box {
    background: #fff;
    padding: 25px 40px;
    border-radius: 5px;
    h4 {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    .tips {
      color: red;
    }
    .remark_box {
      width: 625px;
      margin-top: 15px;
    }
    .lable {
      font-weight: bold;
      margin-right: 10px;
    }
    .btn_group {
      margin-top: 15px;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
