<template>
  <div class="page_">
    <div class="code_">
      <div class="code_c">
        <div>{{ $t('tips[44]') || '生成条形码' }}</div>
        <a
          href="https://test-ssish.oss-cn-hangzhou.aliyuncs.com/TEMP.xlsx"
          class="theme_color"
          >{{ $t('tips[45]') || '下载模板' }}</a
        >
      </div>
      <div class="code_c" :style="{ borderBottom: '0', marginTop: '20px' }">
        <a-upload
          class="upload-list-inline"
          :fileList="fileList"
          :remove="handleRemove"
          :beforeUpload="beforeUpload"
        >
          <a-button class="theme_color" :style="{ width: 'auto' }">
            <a-icon type="folder-open" />{{
              $t('tips[43]') || '点击选择上传文件'
            }}</a-button
          >
        </a-upload>
        <a-button @click="handleUpload" type="primary">{{
          $t('tips[9]') || '上传'
        }}</a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fileList: [],
      uploading: false
    }
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload(file) {
      // this.fileList = [...this.fileList, file];
      this.fileList = [file]
      return false
    },
    handleUpload() {
      const { fileList } = this
      const formData = new FormData()
      fileList.forEach(file => {
        formData.append('file', file)
      })
      this.uploading = true
      this.$apis.barCodeForZips(formData).then(res => {
        const blob = new Blob([res.data], { type: 'application/zip' })
        const filename = res.headers['content-disposition']
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        ;[downloadElement.download] = [filename.split('=')[1]]
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放blob对象

        this.fileList = []
        this.uploading = false
        this.$message.success('upload successfully.')
      })
      // You can use any AJAX library you like
      // reqwest({
      //   url: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      //   method: 'post',
      //   processData: false,
      //   data: formData,
      //   success: () => {
      //     this.fileList = [];
      //     this.uploading = false;
      //     this.$message.success('upload successfully.');
      //   },
      //   error: () => {
      //     this.uploading = false;
      //     this.$message.error('upload failed.');
      //   },
      // });
    }
  }
}
</script>

<style scoped lang="scss">
.page_ {
  height: 100vh;
  width: 100%;
  .code_ {
    width: 470px;
    margin: 50px auto;
    .code_c {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: #e7ecf3 1px solid;
      height: 40px;
    }
  }
  .theme_color {
    color: #00bd59;
  }
}
</style>
